export default {
  name: 'RiskDisclaimer',
	metaInfo () {
		return {
			title: 'Risk Disclaimer',
			meta: [{
				name: 'description',
				content: 'Topic description about "Risk Disclaimer" page',
			},
			{
				name: 'og:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'og:type',
				content: 'website',
			},
			{
				name: 'og:title',
				content: 'Risk Disclaimer',
			},
			{
				name: 'og:description',
				content: 'Topic description about "Risk Disclaimer" page',
			},
			{
				name: 'og:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			},
			{
				name: 'twitter:card',
				content: 'summary_large_image',
			},
			{
				name: 'twitter:domain',
				content: '1tn.com',
			},
			{
				name: 'twitter:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'twitter:title',
				content: 'Risk Disclaimer',
			},
			{
				name: 'twitter:description',
				content: 'Topic description about "Risk Disclaimer" page',
			},
			{
				name: 'twitter:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			}
		],
			link: [{
				rel: 'canonical',
				href: this.createCanonicalUrl()
			}]
		}
	}
}
